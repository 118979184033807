import React from "react";
import { Link } from "react-router-dom";
import homeIcon from "../resources/icons/icons8-home.gif"
import Resume from "../resources/Resume.pdf"

const NavBar = () => {

    return (
        <div id="navBar">
            <Link to={"/"} id="titleLink">
                <img alt="Home" className="homeMainButton" src={homeIcon} width="40px" height="40px"/>
            </Link>
            <div id="navMenu">
                <a href={Resume} className="menuItem linkText">Resume</a>
            </div>
        </div>
    );

}

export default NavBar;