import React from "react";
import ProfilePic from "../resources/profile_pic.jpg"
import Blog from "./Blog"
import Resume from "../resources/Resume.pdf"

const Home = () => {

    return (
        <div id="homePage">
            <div id="intro">
                <div id="profile-pic">
                    <img id="ppimg" src={ProfilePic} height={400}></img>
                    <h5 id="hello" align="left">Hello!</h5>
                </div>
                <div id="desc">
                    <p className="fptext">I am a 9-to-5 Software Engineer, and a 5-to-9 AI Researcher.</p>
                    <p className='fptext'></p>
                    <br/>
                    <p className="fptext">Check back soon to see what I've been up to!</p>
                </div>
            </div>
            <div id="desc">
                <p className="fptext">More updates coming soon! In the meantime, check out my <a href={Resume} className="linkText">Resume</a>.</p>
            </div>
        </div>
    );

}

export default Home;